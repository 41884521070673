<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="md" footer-class="footerClass"
      @shown="uploadDialogOpen"
      @hidden="modalCancel"
      content-class="shadow"
      no-close-on-backdrop
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/><span class="pl-2">{{ alertMsg }}</span>
      </b-alert>

      <div class="container pl-0">
        <b-row>
          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('file.field.parent_folder')" label-for="parent">
              <b-input-group>
                <b-form-input id="parent" type="text" size="sm"
                  v-model="parent.name" 
                  :readonly="true"
                  >
                </b-form-input>
                <b-input-group-append v-if="!isNameReadOnly">
                  <b-button size="sm" variant="info" @click="parentSelectorOpen">{{ $t('button.select') }}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['parentFolder'] != null">
            <b-col v-for="(field, index) in customFieldMap['parentFolder']" :key="'parentFolder'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
      
          <b-col cols="12" md="8" v-if="isNameVisible" class="pr-0">
            <b-form-group :label="$t('file.field.name')" label-for="name">
              <b-input-group>
                <b-form-input id="name" type="text"
                  :data-vv-as="$t('file.field.name')"
                  data-vv-name="name"
                  data-vv-delay="500"
                  v-model="name" 
                  v-validate="{ required: true }"
                  :readonly="isNameReadOnly"
                  :disabled="file.length > 1"
                  autofocus
                  :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'name')"
                  trim 
                  @keydown.native="keydownHandler">
                </b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('name') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['name'] != null">
            <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        
          <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
            <b-form-group :label="$t('field.identifier')" label-for="identifier">
              <b-input-group>
                <b-form-input id="identifier" type="text"
                  :data-vv-as="$t('field.identifier')"
                  data-vv-name="identifier"
                  :maxlength="maxIdentifierLength"
                  v-model="identifier" 
                  :disabled="isIdentifierReadOnly"
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['identifier'] != null">
            <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="isDescriptionVisible" cols="12" class="file-details pr-0">
            <b-form-group :label="$t('file.field.description')" label-for="desc">
              <b-textarea v-model="desc" 
                id="desc" 
                class="rounded-0" 
                :readonly="isDescriptionReadOnly"
                trim
                :disabled="file.length > 1"/>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['description'] != null">
            <b-col v-for="(field, index) in customFieldMap['description']" :key="'description'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" md="8" v-if="isTagVisible" class="pr-0">
              <b-form-group>
                <TagList :readOnly="isTagReadOnly" :holderId="id" :tags="tags" @modified="tagsModified" />
              </b-form-group>
          </b-col>

          <template v-if="customFieldMap['tags'] != null">
            <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        
          <b-col cols="12" md="4" v-if="isColorVisible" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="color" :update="updatedColor"/>
              </div>
          </b-col>

          <template v-if="customFieldMap['color'] != null">
            <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('file.field.file')" label-for="uploadfile">
              <b-input-group v-if="isReadOnly" class="file-group">
                <b-form-input v-model="labelFilename" readonly class="rounded-0 border-0"/>
              </b-input-group>
              <b-input-group v-else @click="fileChange" class="file-edit-group">
                <font-awesome-icon :icon="['far', 'file-arrow-up']" class="file-edit-icon"/>
                <b-form-input v-model="labelFilename" readonly class="rounded-0 form-input-readonly border-0 cursor-pointer"/>
              </b-input-group>
              <b-form-file v-model="file" :id="`uploadfile_${id}`" ref="uploadfile" class="d-none" multiple plain accept="*/*"></b-form-file>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFileError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('file') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col v-if="type" cols="2">{{ $t('file.field.type') }}</b-col><b-col v-if="type" cols="9">{{ type }}</b-col>
          <b-col v-if="size" cols="2">{{ $t('file.field.size') }}</b-col><b-col v-if="size" cols="9">{{ size | byteFormat }}</b-col>
          <b-col cols="2">{{ $t('file.field.created') }}</b-col><b-col cols="9">{{ created }}</b-col>
          <b-col cols="2">{{ $t('file.field.modified') }}</b-col><b-col cols="9">{{ modified }}</b-col>

          <template v-if="customFieldMap['file'] != null">
            <b-col v-for="(field, index) in customFieldMap['file']" :key="'file'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0" v-if="isNoteVisible">
            <b-form-group>
              <NoteList :readOnly="isNoteReadOnly" class="mt-2" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['notes'] != null">
            <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>
       
      <template v-slot:modal-footer="{ cancel }">
        <template v-if="allowManage && (canEdit() || !exists)">
          <b-button size="sm" variant="success" disabled v-if="isSubmitting">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success" :disabled="disableOk" v-else @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('SELECT' === mode? 'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <FolderSelectorModal :currentPath="currentPath" :show.sync="folderSelectorShow" @success="folderSelectorSuccess"/>
    
    <b-modal title="Upload"
      centered
      content-class="shadow"
      no-close-on-backdrop
      v-model="uploadProgressShow" hide-footer>
      <b-alert dismissible fade :show="uploadSuccessShow" variant="success">
        <font-awesome-icon :icon="['far', 'check']"/>&nbsp;&nbsp;{{ uploadSuccessMsg }}
      </b-alert>
      <b-alert dismissible fade :show="uploadAlertShow" variant="danger">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertUploadMsg }}
      </b-alert>
      <b-progress :max="100" height="2rem">
        <b-progress-bar :value="uploadPercentage" :label="`${uploadPercentage.toFixed(0)}%`"/>
      </b-progress>
      <div class="d-flex justify-content-center">
        <b-button class="mt-2" :variant="!uploadSuccessShow && !uploadAlertShow? 'secondary': uploadSuccessShow? 'success':'danger'" @click="uploadProcessClick">
          {{ $t(!uploadSuccessShow && !uploadAlertShow? 'button.cancel': uploadSuccessShow? 'button.done':'button.failed') }}
        </b-button>
      </div>
    </b-modal>

    <template v-if="exists">
      <GenericHistoryModal :show.sync="historyShow" :id="id" entityType="FILE" :customFields="customFields" links="NOTE,TAG" />
      <NoteModal v-if="noteShow" :show.sync="noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { persistNotes, hasNotesChanged } from '@/components/Note/script/crud-util';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { fileService, folderService,
         fileLinkTagService, tagService } from '@/services';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { byteFormat, strFormat, strRandom } from '@/helpers';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

export default {
  name: 'FileDetailsModal',
  components: { 
    FolderSelectorModal: () => import('./FolderSelectorModal.vue'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:        { type: String,   default: 'FILE_NEW' },
    show:      { type: Boolean, required: true },
    currentPath: { type: Object, default: null },
    parentData: { type: Object, default: null },
    mode: {
      type: String,
      default: 'BOTH' // ['SELECT','MANAGE','BOTH']
    }
  },
  data() {
    return {
      permissionName: 'STORAGE_FILE',
      modelInfo: null,
      alertMsg: null,

      folderSelectorShow: false,
      
      name: null,
      type: null,
      created: null,
      modified: null,
      size: 0,
      desc: null,
      identifier: null,
      modalShow: false,
      historyShow: false,
      customData: {},
      readOnly: false,
      
      // uploading a file
      uploadPercentage: 0,
      uploadShow: false,
      uploadProgressShow: false,
      uploadSuccessMsg: null,
      alertUploadMsg: null,
      file: [],
      
      fileId: null,
      parent: {
        uuId: null,
        name: null
      },
      isSubmitting: false,
      noteShow: false,
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      tags: [],
      color: null,
      updatedColor: null,
      
      customFields: [],
      customFieldMap: {},

      maxFileSizeLimit: -1
    }
  },
  created() {
    this.getModelInfo();
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      readOnly: false
    }
    this.originFile = null;
    this.originNotes = [];
    this.originTags = [];
  },
  beforeDestory() {
    this.fieldValidateUtil = null;
    this.originFile = null;
    this.originNotes = null;
    this.originTags = null;
  },
  computed: {
    componentId() {
      return `FILE_FORM_${this.id}`;
    },
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    isReadOnly() {
      return this.mode && 'SELECT' === this.mode || !this.canEdit() || this.readOnly || this.$store.state.epoch.value !== null;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'name');
    },
    showFileError() {
      return fieldValidateUtil.hasError(this.errors, 'file');
    },
    errorShow() {
      return this.alertMsg != null;
    },
    uploadAlertShow() {
      return this.alertUploadMsg != null;
    },
    uploadSuccessShow() {
      return this.uploadSuccessMsg != null;
    },
    labelFilename() {
      if (this.file.length === 0) {
        return this.$t('file.none');
      }
      else if(this.file.length === 1) {
        return this.file[0].name;
      }
      else {
        return this.$t('file.multiple');
      }
    },
    progressCompleted() {
      return this.uploadPercentage == 100;
    },
    exists() {
      return this.id && this.id !== 'FILE_NEW';
    },
    labelTitle() {
      return this.$t('file.title.details');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) && ((!this.exists && this.canAdd(this.permissionName, ['color'])) 
      || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) && ((!this.exists && this.canAdd(this.permissionName, ['identifier']))
      || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isDescriptionVisible() {
      return this.canView(this.permissionName, ['description']) && ((!this.exists && this.canAdd(this.permissionName, ['description']))
      || this.exists)
    },
    isDescriptionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['description']))
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    disableOk() {
      return (this.original.readOnly && this.readOnly) || this.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    },
    file(newObj) {
      if (newObj !== null &&
          newObj.length > 0) {
        if (this.name === null ||
            this.name === '') {
          this.name = newObj.length === 1 ? newObj[0].name : this.$t('file.multiple');
        }
        if (this.name.indexOf('.') !== -1) {
          this.name = this.name.substr(0, this.name.lastIndexOf('.'));
        }
        
        if (newObj.length === 1) {
          this.size = newObj[0].size;
          this.type = newObj[0].type;
        }
        else {
          this.size = 0;
          this.type = this.$t('file.multiple');
          for (var idx = 0; idx < newObj.length; idx++) {
            this.size += newObj[idx].size;
          }
        }
      }
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      if(newValue != this.modalShow) {
        await getCustomFieldInfo(this, 'STORAGE_FILE');
        this.$validator.resume();
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
        }
        this.errors.clear();
        this.modalShow = newValue;
        this.noteShow = false;
        this.originNotes = [];
        this.notes = [];
        this.tags = [];
        this.isSubmitting = false;
        this.alertMsg = null;
        this.file = [];
        if (this.parentData !== null) {
          this.parent.uuId = this.parentData.uuId;
          this.parent.name = this.parentData.name;
          this.color = this.parentData.color;
        }
        else {
          this.parent.uuId = null;
          this.parent.name = null;
        }
        for (const f of this.customFields) {
          delete this.customData[f.name];
        }
        
        if (this.exists) {
          this.loadData();
        }
        else {
          this.name = '';
          this.type = '-';
          this.created = '-';
          this.modified = '-';
          this.size = 0;
          this.desc = '';
          this.notes = [];
          this.identifier = null;
          this.color = null;
          this.originFile = null;
        }
      }
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "STORAGE_FILE"}).then(value => {
        self.modelInfo = value.STORAGE_FILE.properties;
        const found = value.STORAGE_FILE.properties.find(i => i.field == 'size');
        if (found && typeof found.max == 'number') {
          self.maxFileSizeLimit = found.max;
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    uploadDialogOpen() {
      if (!this.exists) {
        // force the user to select a file first
        const fileInput = this.$refs.uploadfile.$el;
        if (fileInput !== null) {
          const evt = new MouseEvent('click');
          fileInput.dispatchEvent(evt);
        }
      }
    },
    loadData() {
      fileService.list(this.id, ['TAG', 'NOTE']).then(response => {
        const data = response.data[response.data.jobCase];
        if (data) {
          this.name = data.name;
          this.type = data.type;
          this.created = data.created;
          this.modified = data.modified;
          this.size = data.size;
          this.desc = data.description;
          this.color = data.color;
          this.identifier = data.identifier;
          this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
          this.notes.sort((a, b) => {
            return b.modified - a.modified;
          });
          this.originNotes = cloneDeep(this.notes);
          const container = this.$refs['comments'];
          if (typeof container !== 'undefined') {
            container.scrollTop = container.scrollHeight;
          }  

          this.readOnly = data.readOnly;
          this.original.readOnly = data.readOnly;
          
          for (const field of this.customFields) {
            if (typeof data[field.name] !== 'undefined') {
              this.customData[field.name] = data[field.name];
            }
          }
          
          this.originFile = {
            name: this.name,
            type: this.type,
            created: this.created,
            modified: this.modified,
            size: this.size,
            desc: this.desc,
            identifier: this.identifier,
            color: this.color,
            ...this.customData
          }
              
          //Setup Tags data
          if (data.tagList && data.tagList.length > 0) {
            const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
            list.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.originTags.splice(0, this.originTags.length, ...list);
            this.tags.splice(0, this.tags.length, ...cloneDeep(list));
          }
          else {
            this.tags = [];
            this.originTags = [];
          }
            
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
      });
    },
    keydownHandler(event) {
      if (event.which === 13) {
        this.ok();
      }
    },
    fileChange() {
      this.$refs.uploadfile.$el.click();
    },
    async filePost(method, data, closeOnSuccess) {
      const result = {
        hasError: false,
        msg: this.$t(`file.${method}`)
      }
      const requireUploadProgress = 'upload' === method || 'update' === method && data.has('file');
      if(requireUploadProgress) {
        this.uploadProgressShow = true;
      }
      let fileId = await fileService[method](data, ( progressEvent ) => {
        if (this.file.length === 1) {
          this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
        }
      }).then((response) => {  
        return response.data.jobClue.uuId;
      })
      .catch(e => {
        result.hasError = true;
        result.msg = this.$t(`file.error.failed_to_${method}`);
        if(e.response && 400 == e.response.status) {
          const jobClue = e.response.data.jobClue;
          const clue = e.response.data.jobClue.clue;
          const handledClues = ['File_size_exceeded', 'Upload_size_exceeded', 'File_virus_found'];
          if(handledClues.includes(clue)) {
            if (clue === 'File_virus_found') {
              result.msg = this.$t(`file.error.${clue.toLowerCase()}`, jobClue.args);
            }
            else {
              result.msg = strFormat(this.$t(`file.error.${clue.toLowerCase()}`), byteFormat(jobClue.args[0]));
            }
          }
        }
      });

      if(result.hasError) {
        this.alertUploadMsg = result.msg;
        return;
      }

      const originParentId = this.parentData? this.parentData.uuId: '-1';
      const newParentId = this.parent && this.parent.uuId? this.parent.uuId: '-1';

      if ('upload' === method || originParentId !== this.parent.uuId) {
        if('upload' !== method  && originParentId !== '-1') {
          await folderService.unlink(originParentId, fileId, 'file')
          .catch(() => {
            result.hasError = true;
            const main = this.$t(`file.error.failed_to_${method}`);
            const detail = this.$t(`file.error.detail.failed_to_unlink`);
            result.msg = `${main} (${detail})`;
          });
        }
        
        if(result.hasError) {
          this.alertUploadMsg = result.msg;
          return;
        }

        if(newParentId !== '-1') {
          await folderService.link(newParentId, fileId, 'file')
          .catch(() => {
            result.hasError = true;
            const main = this.$t(`file.error.failed_to_${method}`);
            const detail = this.$t(`file.error.detail.failed_to_link`);
            result.msg = `${main} (${detail})`;
          });
        }
      }

      // save the color in the profile
      this.updatedColor = data.color;
      
      await this.updateTags(fileId);
      
      if(result.hasError) {
        this.alertUploadMsg = result.msg;
      } else if(requireUploadProgress) {
        this.fileId = fileId;
        this.uploadSuccessMsg = this.$t(`file.${method}`);
      } else {
        const msg = this.$t(`file.${method}`);
        if (closeOnSuccess) {
          this.isSubmitting = false;
          this.$emit('update:show', false);
        }
        this.$emit('success', { msg, fileId: fileId, parent: { uuId: this.parent.uuId, name: this.parent.name } });
      }
    },
    uploadProcessClick() {
      this.uploadProgressShow = false;
      this.uploadSuccess = false;
      if(!this.alertMsg && !this.alertUploadMsg) {
        this.$emit('update:show', false);
        this.$emit('success', { fileId: this.fileId, parent: { uuId: this.parent.uuId, name: this.parent.name } });
      }
    },
    async updateTags(locationId) {
      const result = {
        hasError: false
      }
      const tags = cloneDeep(this.tags);
      const originTags = this.originTags;

      // If any tag has been removed from the list, delete the link
      let toDelete = [];
      for (let i = 0; i < originTags.length; i++) {
        if (!(tags.map(s => s.uuId).includes(originTags[i].uuId))) {
          toDelete.push({uuId: originTags[i].uuId});
        }
      }
      if (toDelete.length > 0) {
        await fileLinkTagService.remove(locationId, toDelete);
      }

      // If any tag has been added to the list, create the link
      let toAdd = [];
      for (let i = 0; i < tags.length; i++) {
        if (!tags[i].uuId) {
          // get the uuId
          tags[i].uuId = await tagService.list({filter: tags[i].name}).then((response) => {
            if (response.data.length !== 0) {
              return response.data[0].uuId;
            }
            return null;
          });
          
          if (tags[i].uuId === null) {
            tags[i].uuId = await tagService.create([{name: tags[i].name}]).then((response) => {
              if (response.data[response.data.jobCase].length !== 0) {
                return response.data[response.data.jobCase][0].uuId;
              }
              return null;
            });
          }
        }
        
        if (tags[i].uuId !== null &&
            !(originTags.map(s => s.uuId).includes(tags[i].uuId))) {
          toAdd.push({uuId: tags[i].uuId});
        }
      }
      if (toAdd.length > 0) {
        await fileLinkTagService.create(locationId, toAdd);
      }


      // // If the old list and new list are not identical, we need to update the
      // // order of tags to the new list order.
      // let reorder = false;
      // if (tags.length !== originTags.length) {
      //   reorder = true;
      // } else {
      //   for (var i = 0; i < tags.length; i++) {
      //     if (tags[i].uuId != originTags[i].uuId) {
      //       reorder = true;
      //       break;
      //     }
      //   }  
      // }

      return result;
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.customData[field.name])) {
          field.showError = true;
          return;  
        }
      }
      this.errors.clear();

      if (this.file.length > 0 && window.FileReader) { 
        let exceedSizeLimit = false
        for (let i = 0, len = this.file.length; i < len; i++) {
          if (this.file[i].size > this.maxFileSizeLimit) {
            exceedSizeLimit = true;
            break;
          }
        }
        
        if (exceedSizeLimit) {
          this.errors.add({
            field: 'file',
            msg: this.$i18n.t(this.file.length > 1? 'file.error.file_size_exceeded_plural' : 'file.error.file_size_exceeded', [byteFormat(this.maxFileSizeLimit)])
          });
        }
      }
      
      
      this.alertMsg = null;
      this.alertUploadMsg = null;
      this.uploadSuccessMsg = null;
      this.uploadPercentage = 0;
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length == 0) {
          this.fileSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
        }
      });
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    async fileSubmit() {
      if (this.exists) {
        const form = new FormData();
        let hasChanged = false;
        let hasCustomDataChanged = false;
        if (this.originFile['name'] !== this.name) {
          form.append('name', this.name != null && this.name.trim().length > 0? this.name :  this.file.length > 0 ? this.file[0].name : null);
          hasChanged = true;
        }
        if (this.originFile['desc'] !== this.desc) {
          form.append('description', this.desc);
          hasChanged = true;
        }
        if (this.identifier && this.originFile['identifier'] !== this.identifier) {
          form.append('identifier', this.identifier);
          hasChanged = true;
        }
        if (this.originFile['color'] !== this.color) {
          form.append('color', this.color);
          hasChanged = true;
        }
        
        if (this.readOnly !== null &&
            this.originFile['readOnly'] !== this.readOnly) {
          form.append('readOnly', this.readOnly);
          hasChanged = true;
        }
        
        if (this.file.length === 1) {
          form.append('file', this.file[0]);
          hasChanged = true;
        }
        
        if (this.originTags.length !== this.tags.length || this.originTags.filter(t => !this.tags.includes(t)).length !== 0) {
          hasChanged = true;
        }

        for (const key of Object.keys(this.customData)) {
          if (this.originFile[key] !== this.customData[key]) {
            hasCustomDataChanged = true;
          }
        }
        
        let method = 'update';
        form.append('uuId', this.id);

        const notesChanged = hasNotesChanged(this.originNotes, this.notes);
          
        const originParentId = this.parentData? this.parentData.uuId: '-1';
        const newParentId = this.parent && this.parent.uuId? this.parent.uuId: '-1';
          
        if (hasChanged || notesChanged || hasCustomDataChanged || (originParentId !== newParentId)) {
          this.isSubmitting = true;
          if (notesChanged) {
            //Notes
            //Remove uuId of new notes before saving
            const notes = cloneDeep(this.notes);
            for (let i = 0, len = notes.length; i < len; i++) {
              if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
                delete notes[i].uuId;
              }
            }      
            // const noteResult = 
            await persistNotes(this.id, this.originNotes, notes);
            //todo: Need to show error to user if error occurs during the note crud operation.
          }
          if (hasCustomDataChanged) {
            const data = { uuId: this.id, ...this.customData };
            await fileService.properties(data);
          }
          if (hasChanged || (originParentId !== newParentId)) {
            await this.filePost(method, form, true);
          } else {
            this.isSubmitting = false;
            this.$emit('success', { fileId: this.fileId, parent: { uuId: this.parent.uuId, name: this.parent.name } });
            this.$emit('update:show', false);
          }
        } else {
          this.$emit('update:show', false);
          this.$emit('success', { fileId: this.fileId, parent: { uuId: this.parent.uuId, name: this.parent.name } });
        }
      }
      else {
        for (var idx = 0; idx < this.file.length; idx++) {
          const form = new FormData();
          form.append('name', this.file.length === 1 ? this.name : this.removeExtension(this.file[idx].name));
          form.append('description', this.desc);
          if (this.identifier) {
            form.append('identifier', this.identifier);
          }
          form.append('file', this.file[idx]);
            
          let method = 'upload';
          await this.filePost(method, form, idx === this.file.length - 1);
          this.uploadPercentage = ((idx + 1) / this.file.length) * 100;
          
          if (Object.keys(this.customData).length !== 0) {
            const data = { uuId: this.fileId, ...this.customData };
            await fileService.properties(data);
          }
        }
        this.uploadPercentage = 100;
      }
    },
    removeExtension(name) {
  
      if (name.indexOf('.') !== -1) {
        name = name.substr(0, name.lastIndexOf('.'));
      }
      return name;
    },
    parentSelectorOpen() {
      this.folderSelectorShow = true;
    }, 
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    folderSelectorSuccess(payload) {
      this.folderSelectorShow = false;
      this.parent.uuId = payload.uuId;
      this.parent.name = payload.name;
      this.color = payload.color ? payload.color : null;
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>

<style lang="scss" scoped>
.file-edit-icon {
  margin: auto 5px auto 10px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.file-edit-group {
  border: 1px solid var(--border-medium);
  cursor: pointer !important;
}
.file-group {
  border: 1px solid var(--border-medium);
}
</style>